import React from "react"

import * as S from "./styled"
import FooterBrand from "../FooterBrand"
import SocialLinks from "../SocialLinks"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { withTrans } from "../../i18n/withTrans"

const Footer = ({ t, i18n }) => (
  <>
{/*     <S.WrapperArrow>
      <AnchorLink to="/#intro">
        <S.ArrowUp />
      </AnchorLink>
    </S.WrapperArrow> */}
    <S.WrapperFooter>
      <S.Text>
        <div className="footer-brand">
          <AniLink to="/">
            <FooterBrand />
          </AniLink>
        </div>
        <p>{t("footer.messageOne")}</p>
      </S.Text>

      <S.TextSocial>
        <p>{t("footer.messageTwo")}</p>
        <SocialLinks />
      </S.TextSocial>

      <S.TextContact>
        <p>{t("footer.messageThree")}</p>
        <span>contact@pyneapp.com</span>
        <span>{t("footer.saoPaulo")}</span>
        <span>{t("footer.newyork")}</span>
        <AniLink to="/privacy">
          <S.PrivacyText>{t("footer.privacyPolicy")}</S.PrivacyText>
        </AniLink>
        <AniLink to="/terms">
          <S.PrivacyText>{t("footer.termCondition")}</S.PrivacyText>
        </AniLink>
      </S.TextContact>

      <S.Stores>
       <a 
       href="https://play.google.com/store/apps/details?id=com.pyne.portal"
       target="_blank"
       rel="noopener noreferrer"   
       className="google"> </a>
       <a
       href="https://apps.apple.com/us/app/pyne-app/id1539983536?ign-mpt=uo%3D2"
       target="_blank"
       rel="noopener noreferrer"  
       className="apple"> </a>
      </S.Stores>

      <S.CopyRight>
        <p>{new Date().getFullYear()}  {t("footer.messageFour")}</p>
        <AniLink to="/privacy">
          <S.PrivacyText className="policy-text">{t("footer.privacyPolicy")}</S.PrivacyText>
        </AniLink>
        <br/>
        <br/>
        <AniLink to="/terms">
          <S.PrivacyText className="policy-text">{t("footer.termCondition")} </S.PrivacyText>
        </AniLink>
      </S.CopyRight>
    </S.WrapperFooter>
  </>
)

export default withTrans(Footer)

