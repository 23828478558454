import styled from "styled-components"
import media from "styled-media-query"
import {ArrowCircleUp} from '@styled-icons/heroicons-solid/ArrowCircleUp'
import badgeGoogle from "../../images/google-play.png"
import badgeApple from "../../images/apple-store.png"


export const ArrowUp = styled(ArrowCircleUp)`
  color: #539E3C;
  width: 40px;
`

export const PrivacyText = styled.span`
  color: #fff;
  font-size:14px;
  &:hover {
    color: #fff;
  }
`

export const WrapperArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    transition: box-shadow 0.2s ease-out;
  }
`

export const WrapperFooter = styled.div`
  margin-top: 100px;
  height: 320px;
  background-color: ${props => props.theme.colors.primary};
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  grid-template-rows: 200px 10px;
  grid-template-areas:
    "textInfo socialMedia contact logo"
    "copyright copyright copyright copyright";

  ${media.lessThan("small")`
    display: block;
    height: 100%;
  `}

  ${media.between("medium", "large")`
    grid-template-columns: 50% 25% 25%;
  `}
`
export const Text = styled.div`
  margin-left: 60px;
  grid-area: textInfo;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  > p {
    font-size: 16px;
    font-weight: 200;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.white};
  }
  ${media.lessThan("small")`
    margin-left: 0;
    display: flex;
    align-items: center;
    margin: 20px;
    > p {
      margin: 10px;
    }
  `}
`

export const TextSocial = styled.div`
  grid-area: socialMedia;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.footer};
  }
  ${media.lessThan("small")`
    display: flex;
    align-items: center;
  `}
`

export const TextContact = styled.div`
  grid-area: contact;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.footer};
    padding: 5px 0px;
  }
  > span {
    font-size: 16px;
    font-weight: 200;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.white};
    padding: 5px 0px;
  }

  .policy-text {
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    padding: 5px 0px;
  }


  ${media.lessThan("small")`
    display: none;
  `}
`

export const Stores = styled.div`
  grid-area: logo;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;

  & .google {
    width: 60%;
    height: 100%;
    background-image: url(${badgeGoogle});
    background-repeat: no-repeat;
    background-size: contain;

  }
  & .apple {
    width: 60%;
    height: 100%;
    background-image: url(${badgeApple});
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export const CopyRight = styled.div`
  grid-area: copyright;
  > p {
    font-size: 16px;
    font-weight: 200;
    font-family: "Josefin Sans", sans-serif;
    color: ${props => props.theme.colors.white};
    text-align: center;
    ${media.lessThan("small")`
      margin-bottom: 20px;
    `}
    margin-top: 60px;
    ${media.lessThan("small")`
      margin-top: 0px;
    `}
  }
  margin-bottom: 25px;
  .policy-text {
    display: none;
  }
  ${media.lessThan("small")`
    text-align: center;
    .policy-text {
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    display: block;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  `}
`
