import React from "react"
import PropTypes from "prop-types"
import Footer from "../Footer"
import '../../styles/index.scss'

import GlobalStyles from "../../styles/global"
import * as S from './styled'

import { withTrans } from "../../i18n/withTrans"

import loadable from '@loadable/component'
//import Navigation from "../Navigation/"
const Navigation = loadable(() => import('../Navigation'))

const Layout = ({ children, t, i18n }) => {
  return (
    <>
    <S.LayoutWrapper>
      <GlobalStyles />
      <div className="nav-mobile">
        <Navigation/>
      </div>
      <S.LayoutMain>
        {children}
      </S.LayoutMain>
      <Footer />
    </S.LayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
