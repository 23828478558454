import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

const Brand = () => {
  const { brandImage } = useStaticQuery(
    graphql`
      query {
        brandImage: file(relativePath: { eq: "pyneBrandWhite.png"}) {
          childImageSharp {
            fluid(maxWidth: 104, maxHeight: 52) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return <Img fluid={brandImage.childImageSharp.fluid} style={{ width: '80px'}}/>
}

export default Brand