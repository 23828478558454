import styled from "styled-components"
import media from "styled-media-query"

export const SocialWrapper = styled.nav`
  width: 100%;
  ${media.lessThan("small")`
    display: flex;
    justify-content: center;
    margin: 20px;
  `}
`

export const SocialLinksList = styled.ul`
display: flex;
justify-content: flex-start;
list-style: none;
${media.lessThan("small")`
  width: 100%;
  justify-content: center;
  > li {
    margin: 0 18px;
  }
`}
`

export const SocialLinksItem = styled.li``

export const SocialLinksLink = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  transition: color 0.5s;
  margin-right: 30px;
  &:hover {
    color: ${props => props.theme.colors.footer};;
  }
`

export const IconWrapper = styled.div`
  fill: #bbb;
  width: 20px;
  height: 20px;
  ${media.lessThan("small")`
  width: 30px;
  height: 30px;
  `}
`