import { Linkedin } from '@styled-icons/entypo-social/Linkedin'
import { Facebook}  from '@styled-icons/boxicons-logos/Facebook'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'

const Icons = {
  Linkedin,
  Facebook,
  Instagram
}

export default Icons