const links = [
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/company/pyneapp-ex/'
  },
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/pyneapp/'
  },
  {
    label: 'Instagram',
    url: 'https://instagram.com/pyneapp.br?igshid=1ujgrzwc5zkxf'
  },
]

export default links