export default {
  title: 'general',

  colors: {
    primary: '#027AD1',
    secondary: '#DCECD8',
    tertiary: '#EBDBCD',

    white: '#FFFFFF',
    black: '#000000',
    grey: '#707070',

    success: '#539E3C',
    info: '#027AD133',
    warning: '#FF7058',

    footer: '#8FE277',
    subtitle: '#455A64',
    titlePrimary: '#27DEBF'
  }
}
