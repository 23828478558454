import styled from "styled-components"
import media from "styled-media-query"; 

export const LayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  & .nav-mobile {
    display: none;
  }
  ${media.lessThan("small")`
    & .nav-mobile {
      display: block;
    }
  `}
`

export const LayoutMain = styled.main`
  flex: 1 0 auto;
  // padding: 0 0 60px 60px;
  ${media.lessThan("small")`
    //padding: 10px 20px;
    //padding: 0;
  `}
  ${media.between("medium", "large")`
    //padding: 0px 60px;
  `}
`